class ProductSlider extends Component {
	constructor(el) {
		super(el);
		this.swiperEl = this.$find('[data-swiper]');
		this.swiper = null;
		this.swiperEl && this.constructSwiper();
	}

	constructSwiper() {
		this.swiper = new Swiper(this.swiperEl, {
			watchOverflow: true,
			slidesPerView: 2,
			pagination: {
				el: this.swiperEl.querySelector('[data-pagination]'),
				clickable: true
			},
			navigation: {
				nextEl: '[data-prev]',
				prevEl: '[data-next]',
			},
			breakpoints: {
				680: {
					slidesPerView: 3,
				},
				767: {
					slidesPerView: 2,
				},
				1120: {
					slidesPerView: 3,
				},
			}
		});
	}
}